// Footer.js

import React from "react";
import "./footer.css"; // Import the CSS file for styling

const Footer = () => {
  return (
    <footer>
      {/* Footer content */}
      <div className="footer-content">
        @2024 CopyRight
      </div>
      <div className="footer-content">
        CIS: TA07300433000027620
      </div>
      <div className="footer-content">
        CIN: IT073004C200098641
      </div>
    </footer>
  );
};

export default Footer;
